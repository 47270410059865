<div class="step-progress__wrapper"><div class="step-progress__wrapper-before" :style="{&quot;background-color&quot;: passiveColor, height: lineThickness + &quot;px&quot; }"></div><div class="step-progress__bar"><div class="step-progress__step" v-for="(step, index) in steps" :style="{
        &quot;--activeColor&quot; : activeColor,
        &quot;--passiveColor&quot; : passiveColor,
        &quot;--activeBorder&quot; : activeThickness + &quot;px&quot;,
        &quot;--passiveBorder&quot; : passiveThickness + &quot;px&quot;
      }" :class="{
        &quot;step-progress__step--active&quot;: index === currentStep,
        &quot;step-progress__step--valid&quot;: index &lt; currentStep
      }"><span>{{ index + 1 }}</span><div class="step-progress__step-icon" :class="iconClass"></div><div class="step-progress__step-label">{{ step }}</div></div></div><div class="step-progress__wrapper-after" :style="{
      transform: &quot;scaleX(&quot; + scaleX + &quot;) translateY(-50%) perspective(1000px)&quot;,
      &quot;background-color&quot;: activeColor,
      height: lineThickness + &quot;px&quot;
    }"></div></div>